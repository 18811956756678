import type { MagniteTargetingKeys } from "scmp-app/components/advertisement/magnite-demand-manager/hooks";

const nativeAdUnits = [
  "d_native1",
  "d_native2",
  "d_native2a",
  "d_native2b",
  "d_native2c",
  "d_native3b",
  "d_native5",
  "m_native2",
  "m_native2a",
  "m_native2b",
  "m_native2c",
  "m_native5",
  "native4",
  "native5",
] as const;

export type NativeAdUnit = (typeof nativeAdUnits)[number];

export function isNativeAdUnit(adUnit: string): adUnit is NativeAdUnit {
  return nativeAdUnits.includes(adUnit as NativeAdUnit);
}

const outstreamAdUnits = ["outstream1"] as const;

export type OutstreamAdUnit = (typeof outstreamAdUnits)[number];

export function isOutstreamAdUnit(adUnit: string): adUnit is OutstreamAdUnit {
  return outstreamAdUnits.includes(adUnit as OutstreamAdUnit);
}

const otherAdUnits = ["privacypolicy/privacypolicypixel"] as const;

const adUnits = [
  ...nativeAdUnits,
  ...outstreamAdUnits,
  ...otherAdUnits,
  "d_banner1",
  "d_banner2",
  "d_banner2a",
  "d_banner2b",
  "d_banner3",
  "d_banner4",
  "d_banner5",
  "d_lrec1",
  "d_lrec2",
  "d_lrec3",
  "d_lrec3a",
  "d_lrec3b",
  "d_lrec4",
  "d_lrec4a",
  "d_lrec4b",
  "d_lrec4d",
  "d_oop3",
  "d_sky1b",
  "hpto1",
  "m_banner1",
  "m_banner2",
  "m_banner3",
  "m_banner3a",
  "m_banner4",
  "m_banner5",
  "m_lrec1",
  "m_lrec2",
  "m_lrec3",
  "m_lrec3a",
  "m_lrec3c",
  "m_lrec3g",
  "m_lrec3h",
  "m_lrec4a",
  "m_lrec4d",
  "m_lrec4e",
  "m_lrec4f",
  "m_oop3",
] as const;

export type AdUnit = (typeof adUnits)[number];

export type Targeting = Omit<
  {
    articletype?: ArticleType;
    hpto?: TargetingHomePageTakeOverType;
    paid?: TargetingPaidType;
    pos?: Position;
    scaid?: AuthorEntityId;
    scnid?: ContentEntityId;
    scsid?: SectionEntityId[];
    sctid?: TopicEntityId;
  } & SentimentTargeting,
  MagniteTargetingKeys
>;

export enum TargetingPaidType {
  Free = "0",
  Paid = "1",
  Sponsored = "2",
}

export enum TargetingHomePageTakeOverType {
  Bottom = "2",
  Top = "1",
}

type AuthorEntityId = string;
type ContentEntityId = string;
type TopicEntityId = string;
type SectionEntityId = string;
type ArticleType = ArticleTypeEntityId[] | "DEFAULT";
type ArticleTypeEntityId = string;

type Position = string;

export type Sentiment = {
  avg_first_second: number;
  avg_sent_1_5: number;
  gs_adult: number;
  gs_client1: number;
  gs_client2: number;
  gs_client3: number;
  gs_hkprotests: number;
  gs_offensive_language: number;
  gs_terrorism: number;
  gs_tobacco: number;
  gs_tragedy: number;
  gs_violence: number;
  headline_score: number;
  headline_score_category: string;
  overall_sentiment_score: number;
  readability_explanation: string;
  readability_notes: string;
  readability_school_level: string[];
  readability_score: number;
  sentiment_category: string;
  sentiment_max: number;
  sentiment_min: number;
  words_tagged_total: string[];
};

export const sentimentTargetingKeys = [
  "avg_first_second",
  "avg_sent_1_5",
  "gs_adult",
  "gs_client1",
  "gs_client2",
  "gs_client3",
  "gs_drugs",
  "gs_gambling",
  "gs_hkprotests",
  "gs_offensive_language",
  "gs_terrorism",
  "gs_tobacco",
  "gs_tragedy",
  "gs_violence",
  "headline_score",
  "readability_school_level",
  "sentiment_category",
  "sentiment_max",
  "sentiment_min",
] as const;

export type SentimentTargeting = Partial<Record<(typeof sentimentTargetingKeys)[number], string>>;
